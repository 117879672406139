import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "./client.css"

function ClientPage() {
  const [page, setPage] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    axios.get(`https://fast-temple-67309-e04e3bd92b97.herokuapp.com/api/pages/${slug}`)
      .then(response => {
        setPage(response.data);
        // Call animateElements after the page data is set
        setTimeout(animateElements, 100);
      })
      .catch(error => console.error('Error fetching page:', error));
  }, [slug]);

  useEffect(() => {
    if (page) {
      updateProgressBar();
    }
  }, [page]);

  function animateElements() {
    const elements = [
      document.querySelector('nav'),
      document.querySelector('.dynamic-dash'),
      ...document.querySelectorAll('.photo-display'),
      ...document.querySelectorAll('.main-image'),
      ...document.querySelectorAll('.overlay-image'),
      ...document.querySelectorAll('.batch-info')
    ];

    elements.forEach((el, index) => {
      setTimeout(() => {
        if (el) {
          el.style.opacity = '1';
          el.style.transform = 'translate(0, 0) scale(1)';
        }
      }, index * 300);
    });
  }

  function updateProgressBar() {
    const progressBar = document.querySelector('.progress');
    if (progressBar && page) {
      const completed = page.batches.reduce((total, batch) => total + batch.numberOfPhotos, 0);
      const percentage = (completed / page.totalPhotos) * 100;
      progressBar.style.width = `${percentage}%`;
    }
  }

    function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    }

    return `${day}${suffix(day)} ${month.toUpperCase()}, ${year}`;
  }

  if (!page) return <div>Loading...</div>;

  return (
    <div>
      <nav>
        <div className="nav-left">{page.title}</div>
        <div className="nav-center">{page.location}, {formatDate(page.date)}</div>
        <div className="nav-right">PGNARISARA.</div>
      </nav>
      <div className="dynamic-dash">
        <div className="dash-content">
          <div className="dash-left">
            <span className="low-opacity">next delivery date:</span> {page.nextDeliveryDate}
          </div>
          <div className="dash-left">
            {page.batches.reduce((total, batch) => total + batch.numberOfPhotos, 0)}
            <span className="low-opacity">/{page.totalPhotos} photos completed</span>
          </div>
        </div>
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
      </div>
      {page.batches.map((batch, index) => (
         <React.Fragment key={index}>
           <div id={`batch${page.batches.length - index}`} className="photo-display">
          <img src={batch.mainImageUrl} alt={`Batch ${batch.batchNumber}`} className="main-image" />
          <img src={batch.overlayImageUrl} alt={`Batch ${batch.batchNumber} Text`} className="overlay-image" />
        </div>
        <div className="batch-info">
          <div className="batch-text">BATCH {batch.batchNumber}</div>
          <a href={batch.photoLink} target="_blank" rel="noopener noreferrer" className="view-photos-btn">VIEW PHOTOS</a>
          <div className="batch-text">{batch.numberOfPhotos} photos</div>
        </div>
      </React.Fragment>
      ))}
    </div>
  );
}

export default ClientPage;