import React from 'react';
import './HomePage.css'; // Import a separate CSS file for styling (optional, see below for inline styles)

function HomePage() {
  return (
    <div className="homepage">
    </div>
  );
}

export default HomePage;
